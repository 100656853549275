import apiClient from "@services/apiClient";

import { GenericApiResource, GenericApiRelationship } from './resource_api';

const customer = {
    ...(new GenericApiResource('customers')),

    deleteProvider: function ({ id, provider_id }) {
        let uri = '/customers/' + id + '/providers/' + provider_id;
        return apiClient.delete(uri);
    },
    addProvider: function ({ id, provider_id }) {
        let uri = '/customers/' + id + '/providers/' + provider_id;
        return apiClient.post(uri);
    }
}

const event = {
    ...(new GenericApiResource('events', ['delete'])),

    importParticipants({ event_id, import_file, import_file_columns }) {

        let uri = '/import';

        var formData = new FormData();
        formData.append('event_id', event_id);

        for (const col of import_file_columns)
            formData.append('check_columns[]', col);

        formData.append('file', import_file);

        return apiClient.post(uri, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    deleteCustomerProvider: function ({ event_id, customer_provider_id }) {
        let uri = `/events/${event_id}/customer-provider/${customer_provider_id}`;
        return apiClient.delete(uri);
    },
    addCustomerProvider({ event_id, customer_provider_id }) {
        let uri = `/events/${event_id}/customer-provider/${customer_provider_id}`;
        return apiClient.post(uri);
    },
    ...GenericApiRelationship({ resource: 'events', relation: 'event_options', exceptions: ['getEventOptions'] }),
    ...GenericApiRelationship({ resource: 'events', relation: 'gates' }),
    async getUnassociatedGates({ event_id, query_params }) {
        let uri = `/events/${event_id}/gates/unassociated`;

        let os = '';
        for (let k in query_params) {
            os += `${k}=${encodeURIComponent(query_params[k])}&`;
        }

        if (os.length > 0) {
            uri += '?' + os;
        }

        let response = await apiClient.get(uri);

        return response.data;
    },
    ...GenericApiRelationship({ resource: 'events', relation: 'receivers' }),
    ...GenericApiRelationship({ resource: 'events', relation: 'templates' }),
    async getUnassociatedReceivers({ event_id, query_params }) {
        let uri = `/events/${event_id}/receivers/unassociated`;

        let os = '';
        for (let k in query_params) {
            os += `${k}=${encodeURIComponent(query_params[k])}&`;
        }

        if (os.length > 0) {
            uri += '?' + os;
        }

        let response = await apiClient.get(uri);

        return response.data;
    },
    ...GenericApiRelationship({ resource: 'events', relation: 'accreditations' }),
    ...GenericApiRelationship({ resource: 'events', relation: 'areas' }),

    getTimetable: async function ({ resource_id, }) {
        let uri = `/events/${resource_id}/timetable`;

        let response = await apiClient.get(uri);

        if (response.status === 200)
            return response.data;
        else
            throw new Error(`Bad response status (${response.status})`);
    },
    getEventTree: async function ({ resource_id }) {
        let uri = `/events/${resource_id}/eventtree`;

        let response = await apiClient.get(uri);

        if (response.status === 200)
            return response.data;
        else
            throw new Error(`Bad response status (${response.status})`);
    },
    saveTimetable: async function ({ resource_id, timetable, }) {
        // let prom = undefined;
        let uri = `/events/${resource_id}/timetable`;

        let response = await apiClient.put(uri, { timetable: timetable });

        if (response.status === 200)
            return response.data;
        else
            throw new Error(`Bad response status (${response.status})`);
    },
    getCheckArrival: async function ({ event_id, query_params }) {
        // return { count: 86, participants: [] }
        let uri = `/events/${event_id}/participants/check-arrivals`;

        let response = await apiClient.get(uri, {
            params: {
                ...query_params
            }
        });

        if (response.status === 200) {
            return response.data
        }
        else
            throw new Error(`Bad response status (${response.status})`);
    },
    setCheckArrival: async function ({ event_id, participant_id, area_id, flow }) {
        let uri = `/events/${event_id}/participants/${participant_id}/check-arrivals`;

        const payload = {
            area_id: area_id ?? -1,
            flow: flow ?? 'IN',
        };

        let response = await apiClient.post(uri, payload);

        if (response.status === 200)
            return response.data;
        else
            throw new Error(`Bad response status (${response.status})`);
    },
    removeCheckArrival: async function ({ event_id, participant_id, check_arrival_id }) {
        let uri = `/events/${event_id}/participants/${participant_id}/check-arrivals/${check_arrival_id}`;

        let response = await apiClient.delete(uri);

        if (response.status === 200)
            return response.data;
        else
            throw new Error(`Bad response status (${response.status})`);
    },
    removeLastHistory: async function ({ event_id, participant_id, type }) {
        let uri = `/events/${event_id}/participants/${participant_id}/remove-last-history/${type}`;

        let response = await apiClient.delete(uri);

        if (response.status === 200)
            return response.data;
        else
            throw new Error(`Bad response status (${response.status})`);
    },
    getEventStats: async function ({ event_id }) {
        let uri = '/dashboard';

        let response = await apiClient.get(uri, { params: { events: [ event_id ] }});

        if (response.status === 200)
            return response.data;
        else
            throw new Error(`Bad response status (${response.status})`);
    },
    importFromApi: async function({ event_id }) {
        const uri = `events/${event_id}/import-from-api`

        let response = await apiClient.post(uri);

        if (response.status === 200)
            return response.data;
        else
            throw new Error(`Bad response status (${response.status})`);
    },
    getParticipantImportDefaultColumns: async function({ event_id }) {
        const uri = `events/${event_id}/import-default-columns`

        let response = await apiClient.get(uri, { params: { events: [ event_id ] }});

        if (response.status === 200)
            return response.data;
        else
            throw new Error(`Bad response status (${response.status})`);
    },
    getNextIdClienteInference: async function ({ event_id }) {
        let uri = `/events/${event_id}/infer_next_id_cliente`;

        let response = await apiClient.get(uri);

        if (response.status === 200)
            return response.data;
        else
            throw new Error(`Bad response status (${response.status})`);
    }
};

const editor = {
    ...(new GenericApiResource('editor')),

    getTemplateEditor: function ({ template_id, event_id }) {
        let uri = '/editor/' + event_id + '/template/' + template_id;
        return apiClient.get(uri);
    }
}

const template = {
    ...(new GenericApiResource('template')),


    async deleteTemplate({ template }) {

        let uri = `/templates/` + template.id;
        let expected_status = 204;

        let response = await apiClient.delete(uri);

        if (response.status == expected_status) {
            return response.data;
        }
        // else {
        //     throw { msg: "Unexpected Status", status: response.status };
        // }

    },


    async deleteTemplates({ templates }) {

        let uri = `/templates/mass-delete`;
        let expected_status = 204;

        const ids = templates.flatMap(item => item.id);
        let pars = {
            'ids': ids
        };

        let q_string = "?ids[]=" + ids.join("&ids[]=");

        let response = await apiClient.post(uri, pars);

        if (response.status == expected_status) {
            return response.data;
        }
        // else {
        //     throw { msg: "Unexpected Status", status: response.status };
        // }

    },

    async saveNewTemplate(template_type_id, event) {

        let uri = `/templates`;
        let expected_status = 201;

        let params = {
            'draft': 1,
            'template_type_id': template_type_id
        };

        if (typeof event != 'undefined') {
            params.event_id = event.event_id;

            //POTREMMO FARLO LATO SERVER - LORENZO
            params.templatable_type = event?.templatable?.type ?? 'Badge';
            params.templatable_id = event?.templatable?.id ?? 1;
        }

        let response = await apiClient.post(uri, params);

        if (response.status == expected_status) {
            return response.data;
        }
        // else {
        //     throw { msg: "Unexpected Status", status: response.status };
        // }

    },
    async saveChangeAssociationTemplate({ template_id, event_id, templatable }) {

        let uri = `/template/` + template_id + `/change-association/` + event_id;
        let expected_status = 201;

        let params = {};
        params.event_id = event_id;
        params.templatable_type = ((templatable.type) ? templatable.type : 'Badge');
        params.templatable_id = ((templatable.id) ? templatable.id : 1);



        let response = await apiClient.post(uri, params);

        if (response.status == expected_status) {
            return response.data;
        }
        // else {
        //     throw { msg: "Unexpected Status", status: response.status };
        // }

    },
    async copyTemplate({ template_id, event_id, templatable }) {

        let uri = `/template/` + template_id + `/copy/` + event_id;
        let expected_status = 201;


        let params = {};
        params.event_id = event_id;
        params.templatable_type = ((templatable.type) ? templatable.type : 'Badge');
        params.templatable_id = ((templatable.id) ? templatable.id : 1);


        let response = await apiClient.post(uri, params);

        if (response.status == expected_status) {
            return response.data;
        }
        // else {
        //     throw { msg: "Unexpected Status", status: response.status };
        // }

    },
    async updateTemplate(params, id) {

        let uri = `/templates/` + id;
        let expected_status = 201;
        let verb = 'post';

        let response = apiClient.put(uri, params);

        if (response.status === expected_status) {
            return response.data;
        }
        // else {
        //     throw { msg: "Unexpected Status", status: response.status };
        // }

    },
    async getTemplateTypes() {

        let uri = '/template_types';
        let expected_status = 200;
        let response = await apiClient.get(uri);

        if (response.status == expected_status) {
            return response.data;
        }
    },

    async getEventTemplateTypes({ event_id, template_type_id }) {
        let uri = '/event_template_types/' + event_id;
        if (typeof template_type_id != 'undefined') {
            uri += '/' + template_type_id;
        }
        let expected_status = 200;
        let response = await apiClient.get(uri);

        if (response.status == expected_status) {
            return response.data;
        }
    },

    async getTemplates({ event_id, query_params }) {

        let uri = '/templates';
        let expected_status = 200;

        let os = '';
        for (let k in query_params) {
            os += `${k}=${encodeURIComponent(query_params[k])}&`;
        }

        if (os.length > 0) {
            uri += '?' + os;
        }

        let response = await apiClient.get(uri);

        if (response.status == expected_status) {
            return response.data;
        }
    },

    async getTemplate(template_id) {

        let uri = '/templates/' + template_id;
        let expected_status = 200;

        let response = await apiClient.get(uri);

        if (response.status == expected_status) {

            return response.data;
        }
    },
    async saveThumbTemplate({ template_id, canvas }) {

        //console.log('canvas',canvas);
        let uri = `/template/${template_id}/savethumb`;
        var formData = new FormData();
        formData.append("file_data", canvas);
        formData.append("file", 'name_of_file');

        return apiClient.post(uri, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    },

}

const provider = {
    ...new GenericApiResource('providers'),
    addCustomer: function ({ id, customer_id }) {
        let uri = '/providers/' + id + '/customers/' + customer_id;
        return apiClient.post(uri);
    },
    deleteCustomer: function ({ id, customer_id }) {
        let uri = '/providers/' + id + '/customers/' + customer_id;
        return apiClient.delete(uri);
    },
    ...GenericApiRelationship({ resource: 'providers', relation: 'customers', exceptions: ['addCustomer', 'deleteCustomer'] }),
}

const participant = {
    ...new GenericApiResource('participants'),
    checkUnique: async function(payload) {
        let uri = '/check-unique';
        try {
            let response = await apiClient.get(uri,{params: payload});
            return response.data;
        } catch(err) {
            console.log(err);
            return false;
        }        
    },
    toggleBadgeDelivery: async function (id,event_id) {

        let uri = '/events/' + event_id + '/participant/' + id + '/toggle-badge-delivery';

        let expected_status = [200, 202];
        let response = await apiClient.post(uri);
        let expected = expected_status.includes(response.status);
        
        return response.data;

    },
    countBadges: async function (id,event_id) {

        let uri = '/events/' + event_id + '/participant/' + id + '/count-badges';

        let expected_status = [200, 202];
        let response = await apiClient.get(uri);
        let expected = expected_status.includes(response.status);
        
        return response.data;

    },

    countHistories: async function (id,event_id,name) {

        let uri = '/events/' + event_id + '/participant/' + id + '/count-histories/' + name;

        let expected_status = [200, 202];
        let response = await apiClient.get(uri);
        let expected = expected_status.includes(response.status);
        
        return response.data;

    },
    printMultiBadge: async function (uri,query_params) {
        let expected_status = [200, 202];

        let os = '';
        for (let k in query_params) {
            os += `${k}=${encodeURIComponent(query_params[k])}&`;
        }

        if (os.length > 0) {
            uri += '?' + os;
        }

        let response = await apiClient.post(uri);
        let expected = expected_status.includes(response.status);
        return response.data;
    },
    printBadge: async function (id) {
        let uri = '/participant/' + id + '/template/BADGE/printpdf';

        let expected_status = [200, 202];
        let response = await apiClient.post(uri);
        let expected = expected_status.includes(response.status);
        if (expected) {
            return response.data;
        }
    },
    printDocument: async function (id,type,template_id) {

        let uri = '/participant/' + id + '/template/' + type + '/printpdf/' + template_id;
        if (template_id == null) {
            uri = '/participant/' + id + '/template/' + type + '/printpdf';
        }

        let expected_status = [200, 202];
        let response = await apiClient.post(uri);
        let expected = expected_status.includes(response.status);
        if (expected) {

            return response.data;
        }
    },
    async getParticipantTemplates({ participant_id, query_params }) {

        let uri = `participant/${participant_id}/templates`;
        let expected_status = 200;

        let os = '';
        for (let k in query_params) {
            os += `${k}=${encodeURIComponent(query_params[k])}&`;
        }

        if (os.length > 0) {
            uri += '?' + os;
        }

        let response = await apiClient.get(uri);

        if (response.status == expected_status) {
            return response.data;
        }
    },
}


const participant_type = {
    ...(new GenericApiResource('participant-types')),
    async getListParticipantTypes({ event_id, query_params }) {

        let uri = `events/${event_id}/participant-types`;
        let expected_status = 200;

        let os = '';
        for (let k in query_params) {
            os += `${k}=${encodeURIComponent(query_params[k])}&`;
        }

        if (os.length > 0) {
            uri += '?' + os;
        }

        let response = await apiClient.get(uri);

        if (response.status == expected_status) {
            return response.data;
        }
    },
    async getAccreditationListParticipantTypes({ event_id, accreditation_id, query_params }) {

        let uri = `events/${event_id}/accreditation/participant-types-list`;
        if (accreditation_id != null) {
            uri += `/${accreditation_id}`;
        }
        let expected_status = 200;

        let os = '';
        for (let k in query_params) {
            os += `${k}=${encodeURIComponent(query_params[k])}&`;
        }

        if (os.length > 0) {
            uri += '?' + os;
        }

        let response = await apiClient.get(uri);

        if (response.status == expected_status) {
            return response.data;
        }
    },
};

const gate = new GenericApiResource('gates');

const receiver = new GenericApiResource('receivers');

const user = new GenericApiResource('users');

const event_option_type = new GenericApiResource('event-option-types', ['save', 'delete']);


export {
    event,
    customer,
    provider,
    participant,
    participant_type,
    gate,
    receiver,
    editor,
    template,
    event_option_type,
    user,
}
