import { defineStore } from 'pinia'
import * as api from '@services/api';


export const useSnackbarStore = defineStore('snackbar', {
    state: () => {
        return {
            show: false,
            color: undefined,
            message: undefined,
            timeout: 5000
        }
    },
    actions: {
        setSuccess(msg = 'Operation Succeeded') {

            this.show = false;
            let self = this
            setTimeout(function () {
                self.message = msg
                self.color = 'success'
                self.show = true
            }, 100);
        },
        setWarning(msg = 'Operation Failed') {
            this.show = false;
            let self = this
            setTimeout(function () {
                self.message = msg
                self.color = 'warning'
                self.show = true
            }, 100);
        },
        setError(msg = 'Operation Failed') {
            this.show = false;
            let self = this
            setTimeout(function () {
                self.message = msg
                self.color = 'error'
                self.show = true
            }, 100);
        },
        close() {
            this.message = undefined
            this.color = undefined
            this.show = false
        },
    }
})