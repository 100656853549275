// stores/user.js
import apiClient from "@services/apiClient.js"
import authClient from "@services/authClient.js"
import { defineStore } from 'pinia'
import router from "@router";
import { mapActions } from "pinia";
import ResourceStore from './resource_store'
import { user as api_user } from "@services/api";

// import { customer as api_customer } from '@services/api'

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      isLoggedIn: false,
      user: null,
      profile: null,
    }
  },
  getters: {
    logged: (state) => !!state.isLoggedIn,
    //authStatus: state => state.status,
    userInfo: (state) => state.user,
    isAdmin: (state) => state.user.profile == 'admin' ?? false,
    isCustomer: (state) => state.user.profile == 'customer',
    isProvider: (state) => state.user.profile == 'provider',
  },
  actions: {
    async getUser(id,pagination_options) {
      const response = await api_user.get(id, pagination_options);
      console.log('user to user data',response);
      return response;
    },
    async saveUser(user_data) {
      //console.log('API USER',api_user);
      const response = await api_user.save(user_data);
      console.log('save User data',user_data);
      
      return response;
    },
    async authUser() {
      try {
        const response = await apiClient.get("/user");
        this.user = response.data;
        this.isLoggedIn = true;
        return true;
      } catch (error) {
        console.log( error );
      }
      return false;
    },
    async checkUnique(payload) {
      console.log(payload);
      try {

       
        const response = await apiClient.get("/check-unique",payload);
        return response.data;
      } catch (error) {
        console.log( error );
      }
      return false;
    },
    async login( payload ) {
      await authClient.get("/sanctum/csrf-cookie");
      try {
        await authClient.post("/login", payload);
        return await this.authUser();
      } catch (error) {
        console.log( "error" );
      }
    },
    async logout() {
      try {
        await authClient.post("/logout");
        this.reset();
        localStorage.removeItem('user');
        router.push('/login').catch(()=>{});
      } catch (error) {
        console.log( error )
      }
    },
    reset() {
      this.$reset();
    }
    // getUser() {
    //   axios.get("/api/user").then( (response) => {
    //     this.user.profile = response.data;
    //   })
    // },
    // async registerUser(login, password) {
    //   try {
    //     let response = await axios.post("/api/login", { login, password });
    //     this.user = response.data;
    //     // showTooltip(`Welcome back ${this.user.name}!`)
    //     console.log( `Welcome back ${this.user.name}!` )
    //   } catch (error) {
    //     // showTooltip(error)
    //     console.log( error )
    //     // let the form component display the error
    //     return error
    //   }
    // },
  },
})