var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c("router-view", { key: _vm.$route.name }),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          staticStyle: { "z-index": "999999" },
          attrs: {
            timeout: _vm.localTimeout,
            fixed: "",
            top: "",
            color: _vm.color,
            dismissible: "",
          },
          on: { click: _vm.close },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function ({ attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: { color: "white", icon: "" },
                        on: { click: _vm.close },
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_c("v-icon", [_vm._v("mdi-close-circle-outline")])],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.localShow,
            callback: function ($$v) {
              _vm.localShow = $$v
            },
            expression: "localShow",
          },
        },
        [_c("span", { domProps: { innerHTML: _vm._s(_vm.localMessage) } })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }